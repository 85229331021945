<template>
  <div
    v-if="financial"
    class="booking-receipt"
   
  >
    <BookingPriceBreakdownReceiptOwner
      v-if="userIsBookingOwner"
      :financial="financial"
      :delivery-distance="deliveryDistance"
      :highlight-line="highlightLine"
      :hide-total="hideTotal"
      :size="size"
      :hide-receipt-footer="hideReceiptFooter"
    />
    <BookingPriceBreakdownReceiptRenter
      v-else
      :financial="financial"
      :rv-snapshot="rvSnapshot ?? null"
      :highlight-line="highlightLine"
      :hide-total="hideTotal"
      :size="size"
      :hide-receipt-footer="hideReceiptFooter"
    />

    <slot name="footer" />
  </div>
</template>

<script setup lang="ts">
import type { highlightedLine } from './types'
import type { FinancialCommon, RvSnapshot } from '~/types'
import type { TableSize } from '~/types/style-guide'

defineProps<{
  financial?: FinancialCommon
  rvSnapshot?: RvSnapshot
  hideTotal?: boolean
  highlightLine?: highlightedLine
  deliveryDistance?: number
  size?: TableSize
  hideReceiptFooter?: boolean
}>()

const { userIsBookingOwner } = useBookingUsers()
</script>
